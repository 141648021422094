/* eslint-disable no-console */
const getCookie = (name) => {
  const pattern = RegExp(name + '=.[^;]*');
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split('=');
    return cookie[1];
  }
  return false;
};

const redirectHandler = (props) => {
  const {
    responseType,
    redirectURL,
    downloadFileURL,
    linkOpenType,
    cookieValidity,
    popupVideo,
    popupVideoURL,
    popupVideoUploadURL,
    customVideoScript,
    defaultClass,
    embeddedFormId,
    currentPostId,
    isParentBlockGatedContentBlock,
  } = props;
  if (responseType === 'redirect' && redirectURL) {
    window.open(redirectURL, linkOpenType);
  } else if (responseType === 'download' && downloadFileURL) {
    window.open(downloadFileURL, '_blank');
  } else if (
    responseType === 'redirectDownload' &&
    redirectURL &&
    downloadFileURL
  ) {
    window.open(redirectURL, linkOpenType);
  } else if (responseType === 'resource-same-page') {
    //only set cookies when parent block is content gater block
    if (isParentBlockGatedContentBlock) {
      const date = new Date();
      date.setTime(date.getTime() + cookieValidity * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      const savedPostIdsCookies = getCookie('postIds');

      const existingCookieSetPostIds = savedPostIdsCookies
        ? JSON.parse(atob(getCookie('postIds')))
        : [];

      existingCookieSetPostIds.push(currentPostId);

      document.cookie = `postIds=${btoa(
        JSON.stringify(existingCookieSetPostIds),
      )}; ${expires} ; path=/`;
    }

    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
  } else if (responseType === 'popup') {
    if (
      (popupVideo === 'url' && popupVideoURL) ||
      (popupVideo === 'upload' && popupVideoUploadURL) ||
      (popupVideo === 'embedded' && customVideoScript)
    ) {
      document
        .getElementById(`${defaultClass}_fancybox_${embeddedFormId}`)
        .click();
    }
  }
};

export default redirectHandler;
